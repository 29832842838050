<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-clipboard-text"
      title="Liste des Commandes"
      class="px-5 py-3"
    >
      <v-row
        class="align-center"
      >
        <v-col
          cols="2"
          md="3"
          lg="3"
        >
          <v-text-field
            v-model="rechercheNom"
            label="Rechercher"
            color="success"
            hide-details
          >
            <template
              v-if="$vuetify.breakpoint.mdAndUp"
              v-slot:append-outer
            >
              <v-btn
                small
                elevation=""
                @click="rechercheCommande(rechercheNom)"
              >
                <v-icon
                  background-color="success"
                >
                  mdi-magnify
                </v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col
          cols="2"
          md="2"
          lg="2"
        >
          <v-select
            v-model="statutChoisi"
            :items="statut"
            label="Statut de la commande"
            item-value="id"
            item-text="text"
            @change="onChange()"
            hide-details
          />
        </v-col>
        <v-col
          cols="2"
          md="2"
          lg="2"
        >
          <!-- <v-btn
            color="success"
            disabled
            @click="trierDate()"
          >
            Trier par date
          </v-btn> -->
        </v-col>
        <v-col
          cols="12"
          md="3"
          lg="3"
        >
          <!-- <v-btn
          class="pa-2 ml-2"
          color="success"
          >
            <download-excel
              :data="items"
              :name="fileNameExport"
            >
            <v-icon
            class="mr-2"
            >mdi mdi-folder-download</v-icon>
              Exporter les commandes
            </download-excel>
          </v-btn> -->
        </v-col>
        <v-col
          cols="2"
          md="1"
          lg="1"
        >
          <v-btn
            class="pa-2"
            color="info"
            @click="Refresh()"
          >
            <v-icon
              class="mr-2"
            >
              mdi-reload
            </v-icon>
            Rafraichir
          </v-btn>
        </v-col>
      </v-row>
      <v-simple-table>
        <thead>
          <tr>
            <th class="primary--text">
              ID
            </th>
            <th class="primary--text">
              Numéro
            </th>
            <th class="primary--text">
              Date
            </th>
            <th class="primary--text">
              Nom client
            </th>
            <th class="primary--text">
              Statut
            </th>
            <th class="primary--text">
              Montant
            </th>
            <th class="primary--text" />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in pageOfItems"
            :key="item.numero"
          >
            <td> {{ item.id_commande }} </td>
            <td> {{ item.numero }} </td>
            <td> Le {{ item.dateFormater }} à {{ item.heureCommande }} </td>
            <td> {{ item.nom }} </td>
            <td> {{ item.labelStatut }} </td>
            <td> {{ (parseFloat(item.montant) + parseFloat(9.90)).toFixed(2) }} € </td>
            <td>
              <v-btn
                small
                color="edit"
                @click="GoDetailsCommande(item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Editer
              </v-btn>
            </td>
          </tr>
        </tbody>
        <!-- <tbody v-else>
          Il n'y a pas de commande pour ce filtre
        </tbody> -->
        <!--   <tr
            v-for="element in test"
            :key="element.id"
          >
            <td>
              <v-btn
                @click="GoDetailsCommande(item)"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Editer
              </v-btn>
            </td>
            <td> {{ element.id_commande }} </td>
            <td> {{ element.numero }} </td>
            <td> {{ element.date_creation }} </td>
            <td> {{ element.nom }} </td>
            <td> {{ element.statut }} </td>
            <td> {{ element.montant }} </td>
          </tr>
        </tbody> -->
      </v-simple-table>
    </base-material-card>

    <jw-pagination
      :items="items"
      @changePage="onChangePage"
    />
    <div class="py-3" />
  </v-container>
</template>

<script>
  // Utilities
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Vue from 'vue'
  import JsonExcel from 'vue-json-excel'

  Vue.use(VueAxios, axios)
  Vue.component('downloadExcel', JsonExcel)

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      // expandOnHover: {
      //   type: Boolean,
      //   default: false,
      // },
    },
    data: () => ({
      websiteid: 1,
      pageOfItems: [],
      items: [],
      fileNameExport: 'filename.xls',
      variable: false,
      orderKey: '',
      rechercheNom: '',
      recherche: false,
      test: [],
      statutChoisi: '',
      itemTemp: [],
      statut: [
        {
          id: 0,
          text: 'Tous les status',
        },
        {
          id: 1,
          text: 'En attente de paiement',
        },
        {
          id: 2,
          text: 'Payé',
        },
        {
          id: 3,
          text: 'Preparation',
        },
        {
          id: 4,
          text: 'Pris en charge',
        },
        {
          id: 5,
          text: 'Annulé',
        },
        {
          id: 6,
          text: 'Supprimé',
        },
        {
          id: 7,
          text: 'Remboursé',
        },
        {
          id: 8,
          text: 'Pré-commande',
        }],
    }),
    computed: {
      evenItems: function () {
        return this.items.filter(function (item) {
          return item.id_commande % 2 === 0
        })
      },
    },
    mounted () {
      this.websiteid = this.$cookie.get('website_id')
      // Récupération des commandes
      Vue.axios.get('https://api.espace-jantes.com/getAllOrders', { params: { websiteid: this.websiteid } }).then((response) => {
        response.data.forEach(element => {
          if (element.statut === 8 || element.id_client === 82 || element.id_client === 89) { // Retrait Pre Commande & utilisateur test
            return false
          }
          var dateSplit = element.date_creation.split('T')
          var heureCommande = dateSplit[1].substr(0, 5)
          element.heureCommande = heureCommande
          var swicthDate = dateSplit[0].split('-')
          element.labelStatut = this.GetStatut(element.statut)
          element.dateFormater = swicthDate[2] + '/' + swicthDate[1] + '/' + swicthDate[0]
          element.montant = element.montant.toFixed(2)
          this.items.push(element)
        })
        var self = this
        setTimeout(function () { self.itemTemp = self.items }, 5000)
      })
    },
    methods: {
      onChangePage (pageOfItems) {
        // update page of items
        this.pageOfItems = pageOfItems
      },
      GetStatut: function (idStatut) {
        var elementToReturn
        switch (idStatut) {
          case 1:
            elementToReturn = 'En attente de paiement'
            break
          case 2:
            elementToReturn = 'Payé'
            break
          case 3:
            elementToReturn = 'Preparation'
            break
          case 4:
            elementToReturn = 'Pris en charge'
            break
          case 5:
            elementToReturn = 'Annulé'
            break
          case 6:
            elementToReturn = 'Supprimé'
            break
          case 7:
            elementToReturn = 'Remboursé'
            break
          case 8:
            elementToReturn = 'Pré-commande'
            break
          default:
            elementToReturn = 'Error'
        }
        return elementToReturn
      },
      GoDetailsCommande: function (item) {
        this.$router.push({ name: 'CommandeDetails', params: { infoCommande: item } })
      },
      getOrderByFilter: function () {
        this.items = []

        this.websiteid = this.$cookie.get('website_id')
        // Récupération des commandes
        Vue.axios.get('https://api.espace-jantes.com/getAllOrdersByStatusAndAll', { params: { websiteid: this.websiteid, statut: this.statutChoisi, all: this.rechercheNom } }).then((response) => {
          response.data.forEach(element => {
            if (element.id_client === 82 || element.id_client === 89) { // Retrait utilisateur test
              return false
            }
            var dateSplit = element.date_creation.split('T')
            var heureCommande = dateSplit[1].substr(0, 5)
            element.heureCommande = heureCommande
            var swicthDate = dateSplit[0].split('-')
            element.labelStatut = this.GetStatut(element.statut)
            element.dateFormater = swicthDate[2] + '/' + swicthDate[1] + '/' + swicthDate[0]
            element.montant = element.montant.toFixed(2)
            this.items.push(element)
          })
          var self = this
          setTimeout(function () { self.itemTemp = self.items }, 5000)
        })
      },
      nameFunction: function () {
        this.items.sort(sortByName())
        function sortByName (key1, key2) {
          return key1.montant > key2.montant
        }
      },
      rechercheCommande: function (rechercheNom) {
        this.getOrderByFilter()
      },
      supprimerNom: function (rechercheNom) {
        this.recherche = false
        this.test = []
      },
      onChange: function () {
        this.getOrderByFilter()
      },
      Refresh: function () {
        this.items = []
        this.statutChoisi = ''
        this.rechercheNom = ''
        // Récupération des commandes
        Vue.axios.get('https://api.espace-jantes.com/getAllOrders', {
          params: { websiteid: this.websiteid },
        }).then((response) => {
          response.data.forEach(element => {
            if (element.statut === 8 || element.id_client === 82 || element.id_client === 89) { // Retrait Pre Commande & utilisateur test
              return false
            }
            var dateSplit = element.date_creation.split('T')
            var heureCommande = dateSplit[1].substr(0, 5)
            element.heureCommande = heureCommande
            var swicthDate = dateSplit[0].split('-')
            element.labelStatut = this.GetStatut(element.statut)
            element.dateFormater = swicthDate[2] + '/' + swicthDate[1] + '/' + swicthDate[0]
            element.montant = element.montant.toFixed(2)
            this.items.push(element)
          })
        })
      },
    },
  }
</script>
<style scoped>
  input[type=text] {
    border: 2px solid black !important;
    border-radius : 4px;
    margin-right : 20px;
  }
</style>
